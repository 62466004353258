<template>
  <div class="scheme-container">
    <div class="scheme-header">
      <div class="scheme-header-title">调解方案</div>
    </div>
    <div class="scheme-body">
      <div class="scheme-body-form">
        <el-form
          :model="FormData"
          ref="FormData"
          :rules="rules"
          label-width="120px"
          class="demo-ruleForm"
          enctype="multipart/form-data"
        >
          <el-form-item label="是否同意调解" prop="resource">
            <el-radio-group @change="changeRadio" v-model="FormData.resource">
              <el-radio label="0">同意</el-radio>
              <el-radio label="1">不同意</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="disagree == true" label="还款金额" prop="amount">
            <el-input
              placeholder="请输入还款金额"
              v-model="FormData.amount"
              @input="amountInput"
              type="number"
            ></el-input>
            <div class="form-tip">
              <div class="form-tip-label" @click="inpTotal">输入总额</div>
              <div class="form-tip-content">
                剩余欠款合计&nbsp;<span class="form-tip-price">{{
                  (
                    caseInfo.outstandingAmount +
                    caseInfo.outstandingInterest +
                    caseInfo.liquidatedDamages
                  ).toFixed(2)
                }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="答复意见" prop="comments">
            <el-input
              type="textarea"
              placeholder="请输入答复意见，建议不超过5000字"
              v-model="FormData.comments"
              maxlength="5000"
              show-word-limit
              @input="commentsInput"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="上传证据">
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :http-request="uploadFile"
              :on-change="changeFile"
              :file-list="fileList"
              multiple
              :auto-upload="false"
            >
              <el-button size="small" type="primary">
                <i class="el-icon-upload2 upload-icon"></i>选择文件
              </el-button>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button
              :class="[
                'formComfirm-btn',
                confirmbtn == true ? 'formComfirmLight' : '',
              ]"
              @click="submitForm('FormData')"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Scheme",
  data() {
    return {
      //   radio: "1",
      FormData: {
        resource: "0",
        amount: "", // 还款进额
        comments: "", // 答复意见
      },
      rules: {
        amount: [
          { required: true, message: "请输入还款金额", trigger: "blur" },
        ],
        resource: [
          { required: true, message: "请选择是否同意调解", trigger: "change" },
        ],
        comments: [
          { required: true, message: "请填写答复意见", trigger: "blur" },
        ],
      },

      disagree: false,
      confirmbtn: false, // 调解方案确认按钮是否高亮

      fileList: [],
      id: "",
      caseInfo: {},
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getCaseInfo();
    if (this.FormData.resource == 0) {
      this.disagree = true;
    } else {
      this.disagree = false;
    }
  },
  methods: {
    // 点击输入总额
    inpTotal() {
      this.FormData.amount =
        this.caseInfo.outstandingAmount +
        this.caseInfo.outstandingInterest +
        this.caseInfo.liquidatedDamages;
    },
    changeFile(file, fileList) {
      this.fileList.push(file);
    },
    getCaseInfo() {
      let caseId = this.id;
      this.$axios
        .get(`/web/web/case/info?id=${caseId}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.caseInfo = res.data.data;
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("caseId", Number(this.id));
          formData.append("mediationMark", Number(this.FormData.resource));
          formData.append("repaymentAmount", Number(this.FormData.amount));
          formData.append("replyMsg", this.FormData.comments);
          // let file = JSON.stringify(this.fileList)
          // formData.append("files", this.fileList);
          this.fileList.map((item) => {
            formData.append("files", item.raw);
          });
          this.$axios
            .post(`/web/web/caseReplyLog/save`, formData, {
              headers: {
                "Content-Type": "mulitipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "答辩完成",
                  type: "success",
                });
                this.$router.push({
                  name: "CaseDetail",
                  query: { id: this.id },
                });
              } else if (res.data.code == 2) {
                this.$message({
                  message: res.data.msg,
                });
                setTimeout((res) => {
                  this.$router.push("/");
                }, 2000);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "请求异常，服务器出错",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    amountInput() {
      if (
        (this.FormData.amount != "" && this.FormData.comments != "") ||
        this.FormData.comments != ""
      ) {
        this.confirmbtn = true;
      } else {
        this.confirmbtn = false;
      }
    },
    commentsInput() {
      if (
        (this.FormData.amount != "" && this.FormData.comments != "") ||
        this.FormData.comments != ""
      ) {
        this.confirmbtn = true;
      } else {
        this.confirmbtn = false;
      }
    },
    changeRadio(value) {
      // console.log(value);
      if (value == 1) {
        this.disagree = false;
        this.FormData.amount = "";
        this.FormData.comments = "";
        this.fileList = [];
      }
      if (value == 0) {
        this.disagree = true;
        this.FormData.amount = "";
        this.FormData.comments = "";
        this.fileList = [];
      }
    },
    uploadFile(params) {},
    submitUpload() {
      // this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/scheme.scss";
</style>